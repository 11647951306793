import close from "../img/close.png";
import "../css/Settings.css";
import { useEffect, useState } from "react";
import { doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { db, settingsCollection } from "../utils/firebase";

const Settings = ({ setShowSettings, user }) => {
  const [settingsId, setSettingsId] = useState(null);
  const [passwordConfig, setPasswordConfig] = useState({
    length: 20,
    includeNumbers: true,
    includeSymbols: true,
  });
  const [suggestedUsernames, setSuggestedUsernames] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isNoSettings, setIsNoSettings] = useState(false);

  useEffect(() => {
    // Start loading
    setIsLoading(true);
    // On load, query settings collection for document with uid equals to current user
    getDocs(query(settingsCollection, where("uid", "==", user.uid))).then((querySnapshot) => {
      if (querySnapshot.empty) {
        setIsNoSettings(true);
      } else {
        querySnapshot.forEach((doc) => {
          // Save settings document
          setSettingsId(doc.id);
          // Deconstruct data
          const { usernames, passwordConfig } = doc.data();
          // Add an empty entry for suggested username and assign to state
          usernames.push("");
          setSuggestedUsernames(usernames);
          // Save password configs to state
          setPasswordConfig(passwordConfig);
        });
      }
      // Finish loading
      setIsLoading(false);
    });
  }, [user.uid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Start loading
    setIsSubmitLoading(true);

    updateDoc(doc(db, "settings", settingsId), {
      usernames: suggestedUsernames.slice(0, -1),
      passwordConfig,
    })
      .then(() => {
        setIsSubmitLoading(false);
        setShowSettings(false);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (value, index) => {
    // Make a copy by using spread operator
    let tmpSuggestedUsernames = [...suggestedUsernames];
    // Change editted username based on passed in index
    tmpSuggestedUsernames[index] = value;
    // If last component is not empty, add another line
    if (tmpSuggestedUsernames.at(-1) !== "") tmpSuggestedUsernames.push("");
    setSuggestedUsernames(tmpSuggestedUsernames);
  };

  const handleDeleteUsername = (index) => {
    // Do not execute if last element is deleted
    if (index === suggestedUsernames.length - 1) return;
    // Make a copy by using spread operator
    let tmpSuggestedUsernames = [...suggestedUsernames];
    // Remove element at specified index
    tmpSuggestedUsernames.splice(index, 1);
    setSuggestedUsernames(tmpSuggestedUsernames);
  };

  return (
    <div className="modal-bg">
      <form className="modal" onSubmit={handleSubmit}>
        <div
          className="modal-close btn"
          onClick={() => {
            setShowSettings(false);
          }}
        >
          <img src={close} alt="Close button" width="50" />
        </div>
        <h1>Settings</h1>
        <h4>Current User: {user.email}</h4>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
              justifyContent: "center",
              fontSize: "20px",
              color: "var(--main-color)",
              gap: "5px",
            }}
          >
            <i className="fas fa-circle-notch fa-spin"></i>
            <p>Loading</p>
          </div>
        ) : isNoSettings ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
              justifyContent: "center",
              fontSize: "15px",
              color: "var(--alert-color)",
              gap: "5px",
              maxWidth: "70%",
              textAlign: "center",
            }}
          >
            <i className="fas fa-exclamation"></i>
            <p>Please contact your administrator to create your initial settings</p>
          </div>
        ) : (
          <>
            <div className="form-control">
              <h4 style={{ fontWeight: "bold" }}>Password configurations:</h4>
              <div className="form-config">
                <h4>Length:</h4>
                <input
                  type="number"
                  value={passwordConfig.length}
                  onChange={(e) => {
                    let tmpConfig = JSON.parse(JSON.stringify(passwordConfig));
                    tmpConfig["length"] = e.target.value;
                    setPasswordConfig(tmpConfig);
                  }}
                />
              </div>
              <div className="form-config">
                <h4>Include numbers:</h4>
                <input
                  type="checkbox"
                  checked={passwordConfig.includeNumbers}
                  onChange={(e) => {
                    let tmpConfig = JSON.parse(JSON.stringify(passwordConfig));
                    tmpConfig["includeNumbers"] = e.target.checked;
                    setPasswordConfig(tmpConfig);
                  }}
                />
              </div>
              <div className="form-config">
                <h4>Include symbols:</h4>
                <input
                  type="checkbox"
                  checked={passwordConfig.includeSymbols}
                  onChange={(e) => {
                    let tmpConfig = JSON.parse(JSON.stringify(passwordConfig));
                    tmpConfig["includeSymbols"] = e.target.checked;
                    setPasswordConfig(tmpConfig);
                  }}
                />
              </div>
            </div>
            <div className="form-control">
              <h4 style={{ fontWeight: "bold" }}>Suggested usernames:</h4>
              {suggestedUsernames.map((username, index) => (
                <div className="form-list" key={index}>
                  <input
                    type="text"
                    value={username}
                    required={index !== suggestedUsernames.length - 1}
                    onChange={(e) => handleChange(e.target.value, index)}
                  />
                  <i className="fa fa-trash fa-lg btn" onClick={() => handleDeleteUsername(index)}></i>
                </div>
              ))}
            </div>
            <button className="form-btn" disabled={isLoading || isSubmitLoading}>
              {isSubmitLoading ? <i className="fas fa-circle-notch fa-spin"></i> : "SAVE"}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default Settings;
