import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { decryptPassword } from "../utils/encryption";
import "../css/PasswordEntity.css";

const PasswordEntity = ({ password, setShowEdit, setShowDelete, setCurrentPassword }) => {
  const [showPasswordText, setShowPasswordText] = useState(true);
  const [passwordText, setPasswordText] = useState("");
  const [copied, setCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const popupRef = useRef();

  useEffect(() => {
    const checkClickOutside = (e) => {
      if (showPopup && !popupRef.current.contains(e.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", checkClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, [showPopup]);

  const hideShowPassword = () => {
    if (showPasswordText) {
      getDoc(doc(db, "passwords", password.id))
        .then((docSnap) => {
          // Set decrypted output to state
          setPasswordText(decryptPassword(docSnap.data().encryptedPassword));
          // Copy to clipboard
          navigator.clipboard.writeText(passwordText);
          // Show copied popup for 2 seconds
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // When hide password is clicked - clear out state, copy popup and clipboard
      setPasswordText("");
      setCopied(false);
      navigator.clipboard.writeText("");
    }
    // Invert showPasswordText state
    setShowPasswordText(!showPasswordText);
  };

  return (
    <div className="password-entity">
      {showPopup && (
        <div className="popup" ref={popupRef}>
          <div
            className="popup-item"
            onClick={() => {
              setShowPopup(false);
              setShowEdit(true);
              setShowPasswordText(true);
              setCurrentPassword(password);
            }}
          >
            Edit
          </div>
          <div
            className="popup-item"
            onClick={() => {
              setShowPopup(false);
              setShowDelete(true);
              setShowPasswordText(true);
              setCurrentPassword(password);
            }}
          >
            Delete
          </div>
        </div>
      )}
      <div className="info">
        <h1>{password.appName}</h1>
        <h3>{password.username}</h3>
        <h3>{showPasswordText ? String.fromCharCode(8226).repeat(20) : passwordText}</h3>
      </div>
      <div className="password-btns">
        <i
          className="fas fa-ellipsis-h fa-lg btn"
          onClick={() => {
            setShowPopup(true);
          }}
        ></i>
        <div className={`eye${copied ? " active" : ""}`} onClick={hideShowPassword}>
          {showPasswordText ? <i className="fas fa-lg fa-eye btn"></i> : <i className="fas fa-lg fa-eye-slash btn"></i>}
        </div>
      </div>
    </div>
  );
};

export default PasswordEntity;
