import { enc } from "crypto-js/core";
import { encrypt, decrypt } from "crypto-js/aes";

export const encryptPassword = (password) => {
  return encrypt(password, process.env.REACT_APP_ENCRYPTION_SECRET).toString();
};

export const decryptPassword = (encryptedPassword) => {
  return decrypt(encryptedPassword, process.env.REACT_APP_ENCRYPTION_SECRET).toString(enc.Utf8);
};
