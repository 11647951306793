import "../css/Navbar.css";
import { signOut } from "@firebase/auth";
import { auth } from "../utils/firebase";
import { useState } from "react";

const Navbar = ({ email, setShiftHome, setShowSettings }) => {
  const [active, setActive] = useState(false);

  const handleSettings = () => {
    setActive(false);
    setShiftHome(false);
    setShowSettings(true);
  };

  const handleLogout = () => {
    signOut(auth).catch((err) => console.log(err));
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <h1>Password Manager</h1>
        <div
          className={`hamburger${active ? " active" : ""}`}
          onClick={() => {
            setActive(!active);
            setShiftHome(!active);
          }}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
      <ul className={`nav-menu${active ? " active" : ""}`}>
        <li className="nav-item">
          <h4>{email}</h4>
        </li>
        <li className="nav-item btn" onClick={handleSettings}>
          <h4>Settings</h4>
        </li>
        <li className="nav-item btn" onClick={handleLogout}>
          <h4>Logout</h4>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
