import { doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { useState, useEffect } from "react";
import close from "../img/close.png";
import { db, settingsCollection } from "../utils/firebase";
import generatePassword from "../utils/generatePassword";
import { encryptPassword } from "../utils/encryption";

const Edit = ({ setShowEdit, currentPassword, user }) => {
  const [appName, setAppName] = useState(currentPassword.appName);
  const [username, setUsername] = useState(currentPassword.username);
  const [password, setPassword] = useState("Enter new password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);
  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [passwordConfig, setPasswordConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDocs(query(settingsCollection, where("uid", "==", user.uid))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { usernames, passwordConfig } = doc.data();
        setSuggestedUsernames(usernames);
        setPasswordConfig(passwordConfig);
        setIsLoading(false);
      });
    });
  }, [user.uid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verify password match
    if (password !== confirmPassword) return setPasswordError("Passwords must match");

    // Start loading
    setIsSubmitLoading(true);

    updateDoc(doc(db, "passwords", currentPassword.id), {
      appName,
      username,
      encryptedPassword: encryptPassword(password),
    })
      .then(() => {
        setIsSubmitLoading(false);
        setShowEdit(false);
      })
      .catch((err) => console.log(err));
  };

  const handleNewPassword = () => {
    const generatedPassword = generatePassword(passwordConfig);
    setPassword(generatedPassword);
    setCopied(false);
  };

  const handleCopied = () => {
    navigator.clipboard.writeText(password);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="modal-bg">
      <form className="modal" onSubmit={handleSubmit}>
        <div
          className="modal-close btn"
          onClick={() => {
            setShowEdit(false);
          }}
        >
          <img src={close} alt="Close button" width="50" />
        </div>
        <h1>Edit Password</h1>
        <div className="form-control">
          <h4>App name</h4>
          <input
            type="text"
            value={appName}
            required
            onChange={(e) => {
              setAppName(e.target.value);
            }}
          />
        </div>
        <div className="form-control">
          <h4>Username</h4>
          <input
            type="text"
            value={username}
            required
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            list="browsers"
          />
          <datalist id="browsers">
            {suggestedUsernames.map((username) => (
              <option value={username} key={username}></option>
            ))}
          </datalist>
        </div>
        <div className="form-control">
          <h4>Password</h4>
          <div className="password-form-item">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {showPassword && (
              <div className={`copy-password${copied ? " active" : ""}`} onClick={handleCopied}>
                <i className="far fa-copy"></i>
              </div>
            )}
            <div className="new-password" onClick={handleNewPassword}>
              <i className="fas fa-sync"></i>
            </div>
          </div>
        </div>
        <div className="form-control">
          <h4>Confirm password</h4>
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            required
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          <span className="incorrect-creds">{passwordError}</span>
          <div className="show-password-checkbox">
            <input
              type="checkbox"
              name="showPassword"
              onClick={() => {
                setShowPassword(!showPassword);
                navigator.clipboard.writeText("");
              }}
            />
            <label htmlFor="showPassword">Show Password</label>
          </div>
        </div>
        <button className="form-btn" disabled={isLoading || isSubmitLoading}>
          {isSubmitLoading ? <i className="fas fa-circle-notch fa-spin"></i> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
};

export default Edit;
