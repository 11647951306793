import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "./utils/firebase";
import Home from "./components/Home";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import "./css/App.css";

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser({
          email: user.email,
          uid: user.uid,
        });
      } else {
        // No user signed in
        setUser(null);
      }
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40vh",
        justifyContent: "center",
        fontSize: "25px",
        color: "var(--main-color)",
        gap: "5px",
      }}
    >
      <i className="fas fa-circle-notch fa-spin"></i>
      <p>Loading</p>
    </div>
  ) : user ? (
    <Home user={user} />
  ) : (
    <Login />
  );
};

export default App;
