import { doc, deleteDoc } from "firebase/firestore";
import "../css/Delete.css";
import { db } from "../utils/firebase";

const Delete = ({ setShowDelete, id }) => {
  const handleDelete = () => {
    deleteDoc(doc(db, "passwords", id))
      .then(() => setShowDelete(false))
      .catch((err) => console.log(err));
  };

  return (
    <div className="modal-bg">
      <div className="delete-modal">
        <h3>Are you sure you want to delete?</h3>
        <div className="delete-btns">
          <button
            className="cancel-btn"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Cancel
          </button>
          <button className="confirm-btn" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
