import { useState } from "react";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../utils/firebase";
import "../css/Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setError(null);
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/user-not-found":
            setError("Invalid email!");
            break;
          case "auth/wrong-password":
            setError("Invalid password!");
            break;
          default:
            setError("Invalid credentials!");
        }
      });
  };

  return (
    <div className="login-bg">
      <div className="login-box">
        <h1>Password Manager</h1>
        {error && <div>{error}</div>}
        <div className="form-control">
          <h4>Email</h4>
          <input
            type="text"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-control">
          <h4>Password</h4>
          <input
            type="password"
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <button className="form-btn" onClick={handleLogin}>
          LOGIN
        </button>
      </div>
    </div>
  );
};

export default Login;
