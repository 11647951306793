import { useEffect, useState } from "react";
import { onSnapshot, query, where } from "firebase/firestore";
import { passwordsCollection } from "../utils/firebase";
import Navbar from "./Navbar";
import PasswordEntity from "./PasswordEntity";
import Add from "./Add";
import add from "../img/add.png";
import Edit from "./Edit";
import Delete from "./Delete";
import "../css/Home.css";
import Settings from "./Settings";

const Home = ({ user }) => {
  const [passwords, setPasswords] = useState([]);
  const [search, setSearch] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({});
  const [shiftHome, setShiftHome] = useState(false);
  const [isLoadingPasswords, setIsLoadingPasswords] = useState(false);

  useEffect(() => {
    // Start loading
    setIsLoadingPasswords(true);
    // Query passwords collection for documents with uid equals to current user
    const unsubscribe = onSnapshot(query(passwordsCollection, where("uid", "==", user.uid)), (querySnapshot) => {
      // Initialise list
      let passwordsList = [];
      // Iterate over found documents
      querySnapshot.docs.forEach((doc) => {
        // Extract data for password entity
        const { appName, username } = doc.data();
        passwordsList.push({
          id: doc.id,
          appName,
          username,
        });
      });
      // Set data to state
      setPasswords(passwordsList);
      // Finish loading
      setIsLoadingPasswords(false);
    });

    return unsubscribe;
  }, [user.uid]);

  // Utility functions
  const sortAndFilterPasswords = (passwords) => {
    return [...passwords]
      .sort((a, b) =>
        a.appName.toUpperCase() > b.appName.toUpperCase()
          ? 1
          : b.appName.toUpperCase() > a.appName.toUpperCase()
          ? -1
          : 0
      )
      .filter((password) => password.appName.toUpperCase().includes(search.toUpperCase()));
  };

  return (
    <div className="home-bg">
      {showAdd && <Add setShowAdd={setShowAdd} user={user} />}
      {showEdit && <Edit setShowEdit={setShowEdit} currentPassword={currentPassword} user={user} />}
      {showDelete && <Delete setShowDelete={setShowDelete} id={currentPassword.id} />}
      {showSettings && <Settings setShowSettings={setShowSettings} user={user} />}
      <Navbar email={user.email} setShiftHome={setShiftHome} setShowSettings={setShowSettings} />
      <div className={`home-container${shiftHome ? " active" : ""}`}>
        <div className="flex-container">
          <div
            className="add-btn btn"
            onClick={() => {
              setShowAdd(true);
            }}
          >
            <img src={add} alt="add button" width="50" />
          </div>
          <div className="search">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Search app name..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <div
              className="cancel-search"
              onClick={() => {
                setSearch("");
              }}
            >
              <i className="fas fa-times"></i>
            </div>
          </div>
          {isLoadingPasswords ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "50px",
                justifyContent: "center",
                fontSize: "20px",
                color: "var(--main-color)",
                gap: "5px",
              }}
            >
              <i className="fas fa-circle-notch fa-spin"></i>
              <p>Loading</p>
            </div>
          ) : (
            sortAndFilterPasswords(passwords).map((password) => {
              return (
                <PasswordEntity
                  key={password.id}
                  password={password}
                  setShowEdit={setShowEdit}
                  setShowDelete={setShowDelete}
                  setCurrentPassword={setCurrentPassword}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
